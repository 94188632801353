
import { defineComponent, PropType } from "vue-demi";
import { Banner } from "@/interfaces/banner/banner.dto";
import { ClockIcon, RefIcon, EditIcon, TrashIcon } from '@/plugins/icons';
import dateFilter from '@/filters/date.filter';

export default defineComponent({
  name: 'Banner',
  props: {
    banner: {
      type: Object as PropType<Banner.Dto>,
      required: true
    }
  },
  methods: {
    dateFilter,
    modal(id: string) {
      this.$store.commit('createModal', { id, data: this.banner });
    },
  },
  components: {
    ClockIcon, RefIcon, EditIcon, TrashIcon
  }
})
