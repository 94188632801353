
import { defineComponent } from "vue-demi";
import BannerMain from "@/components/admin/banner/index.vue";

export default defineComponent({
  name: "BannerView",
  components: {
    BannerMain,
  },
});
