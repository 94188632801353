
import { defineComponent } from "vue-demi";
import { PlusIcon } from '@/plugins/icons';
import { Banner } from "@/interfaces/banner/banner.dto";
import { useAPI } from "@/use";
import CommonButton from '@/components/common/button/Button.vue';
import CommonBanner from './Banner.vue';
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: 'AdminBannerMain',
  data() {
    return {
      banners: [] as Array<Banner.Dto>,
    }
  },
  created() {
    this.getBannerListMethod();
  },
  mounted() {
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
  },
  methods: {
    modal(id: string, data: Banner.Dto) {
      this.$store.commit('createModal', { id, data: data || {} });
    },
    async getBannerListMethod() {
      const result = await useAPI().banner.getListMethod();
      this.banners = result.data;
    },
    // Emitter
    onEmits() {
      this.emitter.on('admin-banner', this.bannerCreatedEmitter);
      this.emitter.on('admin-banner-edit', this.bannerUpdatedEmitter);
      this.emitter.on('admin-banner-delete', this.bannerDeletedEmitter);
    },
    offEmits() {
      this.emitter.off('admin-banner', this.bannerCreatedEmitter);
      this.emitter.off('admin-banner-edit', this.bannerUpdatedEmitter);
      this.emitter.off('admin-banner-delete', this.bannerDeletedEmitter);
    },
    bannerCreatedEmitter(data: Banner.Dto) {
      this.banners = [...this.banners, data];
    },
    bannerUpdatedEmitter(data: Banner.Dto) {
      const currentDoc = (this.banners as Array<dynamicsObject>).find(e => e._id === data._id);
        if (currentDoc) {
          const keys = Object.typedKeys(data);
          for (const key of keys) currentDoc[key] = data[key];
        }
    },
    bannerDeletedEmitter(data: string) {
      this.banners = this.banners.filter(e => e._id !== data);
    },
    
  },
  components: {
    CommonButton,
    PlusIcon,
    CommonBanner
  }
})
